<template>
    <div class="">
		<div class="crumbs">
			<el-breadcrumb separator="/">
				<el-breadcrumb-item>
					<i class="el-icon-lx-cascades"></i> 开户行管理
				</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
        <div class="handle-box">
            <el-button @click="handleAdd">新增</el-button>
        </div>
        <el-table
            :data="tableData"
            border
            class="table"
            ref="multipleTable"
            header-cell-class-name="table-header"
        >
            <el-table-column prop="id" label="ID"  align="center"></el-table-column>
            <el-table-column prop="bank" label="开户行名称" align="center"></el-table-column>
            <el-table-column label="操作" width="180" align="center">
                <template #default="scope">
                    <el-button
                        type="text"
                        icon="el-icon-edit"
                        @click="handleEdit(scope.row)"
                    >编辑</el-button>

                    <el-button
                        type="text"
                        icon="el-icon-delete"
                        style="color:red;"
                        @click="handleDel(scope.row)"
                    >删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-dialog
            :title="editQuery.id?'编辑银行':'新增银行'"
            v-model="dialogVisible"
            width="30%">
            <el-form ref="form" :model="editQuery" label-width="70px">
                <el-form-item label="开户银行">
                    <el-input v-model="editQuery.bank"></el-input>
                </el-form-item>
            </el-form>
            <template #footer>
                <span class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="confirm">确 定</el-button>
                </span>
            </template>
        </el-dialog>
    </div>
</template>

<script>
export default {
    data() {
        return {
            tableData: [],
            query: {
                title: '',
            },
            editQuery: {},
            dialogVisible: false,
            requesting: false
        }
    },
    created() {
        this.getList()
    },
    methods: {
        getList() {
            this.$axios({
                    url: '/admin/bank/getList',
                    method: 'get',
                    params: {
                        ...this.query
                    }
                }).then(({data,code}) => {
                    if(code==200){
                        this.tableData = data.items
                    }
                }).catch(err => {
                    console.log(err)
            });
        },
        handleEdit(item) {
            this.editQuery = item
            this.dialogVisible = true
        },
        handleDel(item) {
            this.$confirm('此操作将永久删除该项, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.delItem(item)
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        delItem(item) {
            this.$axios({
                    url: '/admin/bank/del',
                    method: 'delete',
                    params: {
                        id: item.id
                    }
                }).then(({code}) => {
                    this.requesting = false
                    if(code==200){
                        this.getList()
                        this.$message.success("操作成功")
                    }
                }).catch(err => {
                    this.requesting = false
                    console.log(err)
            });
        },
        handleAdd() {
            this.editQuery = {
                bank: ''
            }
            this.dialogVisible = true
        },
        confirm() {
            let url = '/admin/bank/add'
            if(this.editQuery.id) {
                url = '/admin/bank/edit'
            }
            if(this.requesting) return
            this.requesting = true
            this.$axios({
                    url,
                    method: 'post',
                    data: {
                        ...this.editQuery
                    }
                }).then(({code}) => {
                    this.requesting = false
                    if(code==200){
                        this.dialogVisible =false
                        this.editQuery = {}
                        this.getList()
                        this.$message.success("操作成功")
                    }
                }).catch(err => {
                    this.requesting = false
                    console.log(err)
            });
        }
    }
}
</script>

<style scoped>
    .handle-box {
        padding: 5px 0;
    }
</style>